import type { NextApiRequest, NextApiResponse } from 'next'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPluginExpress from '@bugsnag/plugin-express'

const COMMON_CONFIG = {
  releaseStage: process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.NODE_ENV,
  appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
}

if (typeof window === 'undefined') {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_SERVER_API_KEY || '',
    plugins: [new BugsnagPluginReact(), BugsnagPluginExpress],
    ...COMMON_CONFIG,
  })
} else {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_BROWSER_API_KEY || '',
    plugins: [new BugsnagPluginReact()],
    ...COMMON_CONFIG,
  })
}

//
/**
 * A wrapper for next.js API routes with Bugsnag
 * @param apiRoute the API route being wrapped
 * @returns wrapped API route function
 */
export const withErrorHandling = (
  apiRoute: (req: NextApiRequest, res: NextApiResponse) => Promise<void>,
) => {
  const { requestHandler, errorHandler } = Bugsnag.getPlugin('express')!

  return async (req: NextApiRequest, res: NextApiResponse): Promise<void> => {
    try {
      await new Promise((resolve, reject) => {
        requestHandler(req, res, (result: Error | unknown) => {
          if (result instanceof Error) {
            return reject(result)
          }

          return resolve(result)
        })
      })
      await apiRoute(req, res)
    } catch (error) {
      errorHandler(error, req, res, () => {
        throw error
      })
    }
  }
}

export default Bugsnag
